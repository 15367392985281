import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBQGcl0KCofGJv25cog4pgWGsDStWwI_xs",
  authDomain: "eihl-swapp.firebaseapp.com",
  projectId: "eihl-swapp",
  storageBucket: "eihl-swapp.appspot.com",
  messagingSenderId: "440937830352",
  appId: "1:440937830352:web:6dca19ad4c03f2c6a04bd5",
  measurementId: "G-C47RQ15T38"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const currentUser = auth.currentUser;

export { db, storage, auth, currentUser };
