import Vue from "vue";
import VueRouter from "vue-router";

//store
import store from "../store/index.js";

// auth routes
import Register from "../views/auth/register.vue";
import RegisterAdmin from "../views/auth/register-admin.vue";
import LoginProgress from "../views/auth/login-progress.vue";
import UserProfile from "../views/auth/user-profile.vue";
import ResetPassword from "../views/auth/reset-password.vue";


// admin routes
import Events from "../views/admin/events.vue";
import Passes from "../views/admin/passes.vue";
import Questions from "../views/admin/questions.vue";
import Venues from "../views/admin/venues.vue";
import Users from "../views/admin/users.vue";
import LookupTables from "../views/admin/lookup-tables.vue";
import LookupValues from "../views/admin/lookup-values.vue";


// normal user routes
import Contact from "../views/contact-us.vue";
import CheckInEvent from "../views/check-in-event.vue";
import EventsList from "../views/events-list.vue";
import PreEventCert from "../views/pre-event-cert.vue";
import Restricted from "../views/auth/restricted.vue";
import Pending from "../views/auth/pending.vue";

// main route 
import Start from "../views/start.vue";
//import Home from "../views/home.vue";

import LandingPage from "../views/landing-page";

Vue.use(VueRouter);

const routes = [
   // *  MAIN ROUTE 
  {
    path: "/access",
    name: "Start",
    component: Start,
  },
  {
    path: "/coming-soon",
    name: "LandingPage",
    component: LandingPage,
  },
  // *  AUTH ROUTES
  {
    path: "/register", // ? register for normal user
    name: "Register",
    component: Register,
    // // route level code-splitting
    // // this generates a separate chunk (about.[hash].js) for this route
    // // which is lazy-loaded when the route is visited.
    // // component: () =>
    // //   import(/* webpackChunkName: "register" */ "../views/auth/register.vue"),
  },
  {
    path: "/register-admin", // ? register for admin
    name: "RegisterAdmin",
    component: RegisterAdmin,
    // // component: () =>
    // //   import(/* webpackChunkName: "register" */ "../views/auth/register-admin.vue"),
  },
  {
    path: "/login-progress",
    name: "LoginProgress",
    component: LoginProgress,
    // // component: () =>
    // //   import(/* webpackChunkName: "login-progress" */ "../views/auth/login-progress.vue"),
  },
  {
    path: "/restricted",
    name: "Restricted",
    component: Restricted,
  },
  {
    path: "/pending",
    name: "Pending",
    component: Pending,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 10,
      requiresUserStatus: ['APPROVED']
    },
  },
  // {
  //   path: '/checkinevent/:id',
  //   name: 'Check In Event',
  //   component: () => import(/* webpackChunkName: "home" */ '../views/checkinevent.vue')
  // },'

  // *  ADMIN ROUTES
  {
    path: '/admin/venues',
    name: 'Venues',
    component: Venues,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 100,
      requiresUserStatus: ['APPROVED']
    },
    // // component: () => import(/* webpackChunkName: "venues" */ '../views/admin/venues.vue')
  },
  {
    path: '/admin/passes',
    name: 'Passes',
    component: Passes,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 100,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () => import(/* webpackChunkName: "passes" */ '../views/admin/passes.vue')
  },
  {
    path: '/admin/questions',
    name: 'Questions',
    component: Questions,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 100,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () => import(/* webpackChunkName: "questions" */ '../views/admin/questions.vue')
  },
  {
    path: '/admin/events',
    name: 'Events',
    component: Events,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 100,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () => import(/* webpackChunkName: "events" */ '../views/admin/events.vue')
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: Users,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 100,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () => import(/* webpackChunkName: "events" */ '../views/admin/events.vue')
  },
  {
    path: '/admin/lookuptables',
    name: 'Lookup Tables',
    component: LookupTables,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 100,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () => import(/* webpackChunkName: "events" */ '../views/admin/events.vue')
  },
  {
    path: '/admin/lookupvalues',
    name: 'Lookup Values',
    component: LookupValues,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 100,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () => import(/* webpackChunkName: "events" */ '../views/admin/events.vue')
  },  
  {
    path: "/",
    name: "Events List",
    component: EventsList,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 10,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () => import(/* webpackChunkName: "home" */ "../views/home.vue"),
  },
   // *  NORMAL USER ROUTES
   {
    path: "/contact-us",
    name: "Contact",
    component: Contact,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 10,
      requiresUserStatus: ['APPROVED', 'PENDING', 'SUSPENDED', 'ARCHIVED']
    },
  },
  {
    path: "/events/:action",
    name: "Event List",
    component: EventsList,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 10,
      requiresUserStatus: ['APPROVED']
    },
    ////component: () =>
    ////  import(/* webpackChunkName: "checkin" */ "../views/events-list.vue"),
  },
  {
    path: "/preeventcert/:id",
    name: "Pre Event Certification",
    component: PreEventCert,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 10,
      requiresUserStatus: ['APPROVED']
    },
    // // component: () =>
    // // import(/* webpackChunkName: "preevent" */ "../views/pre-event-cert.vue"),
  },
  {
    path: "/checkinevent/:id",
    name: "Check In Event",
    component: CheckInEvent,
    meta: {
      requiresAuth: true,
      requiresUserLevel: 10,
      requiresUserStatus: ['APPROVED']
    },
    // // component: () =>
    // //   import(/* webpackChunkName: "checkinevent" */ "../views/check-in-event.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  //const userLevel = to.matched.find(record => record.meta.requiresUserLevel).meta.requiresUserLevel;
  //const requiresUserLevel = to.matched.some(record => record.meta.requiresUserLevel);
  
  if (requiresAuth) {
    let checkUser = store.getters.currUser;
    navigator.serviceWorker.register('/service-worker.js').then(reg => {
      // sometime later…
      reg.update();
    });
    // console.log("CURR USER ROUTER: " + JSON.stringify(checkUser, null, 2));
    if (!checkUser) {
      // TODO - switch back to access
      next("/access");
      // // next("/access");
    } else {
      const userLevel = to.matched.find((record) => record.meta.requiresUserLevel).meta.requiresUserLevel;
      const requiresUserLevel = to.matched.some((record) => record.meta.requiresUserLevel);
      //  console.log("userLevel: " + userLevel);
      
      const requiresUserStatus = to.matched.some((record) => record.meta.requiresUserStatus);
      const userStatus = to.matched.find((record) => record.meta.requiresUserStatus).meta.requiresUserStatus;

      if (requiresUserLevel && parseInt(checkUser.userLevel) < userLevel ) {
      // console.log("forbidden access");
        next("restricted");
    //   }else if (requiresUserStatus && !userStatus.includes(checkUser.status) ){
    //     next("pending");
      }else if (requiresUserStatus && userStatus === ''){
        next();
      }
      next();
    }
  } else next();

  
});

export default router;
