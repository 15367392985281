<template>
  <v-row no-gutters class="d-flex justify-center">
    <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8" class="">
    <v-row class="pt-4 px-5 justify-center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"> 
        <div class="heading6 white--text">Admin User Registration<br /></div>
        <p class="mt-1 text-left body2">
          Please use the form below to register as an admin user.  
        </p>
      </v-col>
    </v-row>
    
    <!--start of ACCESS-CODE-OVERLAY-->
    <v-overlay v-if="!authSuccess" :opacity="1"> 
      <v-dialog v-if="!authSuccess" v-model="dialogLogin" persistent style="z-index: 3000 !important;" width="500">
        <v-card class="login" color="background">
          <v-card-text>
              <!-- start of TITLE-ROW-->
              <v-row align="center" class="mt-4">
                <v-col cols="12">
                  <p class="headline white--text text-left font-weight-thin" >International Ice Hockey Federation Pre-Event Screening<br /><strong class="secondary--text">Admin Registration</strong></p>
                </v-col>
                <v-col cols="12" class="mt-0">
                  <p class="subtitle1 white--text">
                    Please enter the access code you received in order to proceed
                    to the registration.
                  </p>
                </v-col>
              </v-row>
              <!-- end of TITLE-ROW-->
              <v-row>
                <v-col cols="12">
                  <v-row class="justify-center">
                    <!-- ACCESS-CODE-INPUT-->
                    <v-text-field
                      label="Enter Access Code"
                      v-model.trim="accessCode"
                      dark
                      filled
                      type="password"
                      v-on:keyup.enter="enterAccessCode()"
                    ></v-text-field>
                  </v-row>
                  <!-- start of ERROR-MESSAGE-->
                  <v-row class="justify-center ma-0 pa-0">
                    <transition name="custom-classes-transition" enter-active-class="animated flash">
                      <span
                        v-show="authCode === 0"
                        :class="{ 'error--text': !authSuccess }"
                        class="subtitle1 font-weight-bold"
                        >{{ authMessage }}</span>
                    </transition>
                  </v-row>
                  <!-- end of ERROR-MESSAGE-->
                </v-col>
                <v-col cols="12">
                  <v-row class="justify-center">
                    <!-- ENTER-ACCESS-CODE-BUTTON-->
                    <v-btn
                      class="secondary" large
                      @click="enterAccessCode()"
                      >Enter</v-btn>
                  </v-row>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
     <!--end of ACCESS-CODE-OVERLAY-->

     <!-- start of USER-INPUT-->
    <v-row class="px-5 justify-center"> 
      <v-col cols="12" xs="12" sm="6">
        <v-window v-model="step">
          <!--GENERAL-DETAILS-WINDOW-->
          <v-window-item :value="1">
            <v-form ref="registerUser" v-model="valid" lazy-validation>
              <v-text-field
                solo
                class="mb-1"
                v-model.trim="user.email"
                label="Email"
                hide-details="auto"
                :rules="emailRules"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.firstName"
                label="First Name"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.lastName"
                label="Last Name"
                hide-details="auto"
                :rules="nameRules"
                required
              ></v-text-field>
            </v-form>
            <!--NEXT-BUTTON-->
            <div class="text-right mt-4">
              <v-btn @click="next" :loading="loading1" :disabled="loading1" large class="buttonmin">Next</v-btn>
            </div>
          </v-window-item>

            <!--GENERAL-DETAILS-WINDOW-2 -->
          <v-window-item :value="2">
            <v-form ref="registerUser2" v-model="valid" lazy-validation>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.company"
                label="Company/Organisation"
                hide-details="auto"
                :rules="confirmRulesCompany"
                required
              ></v-text-field>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.jobTitle"
                label="Job title"
                hide-details="auto"
                :rules="jobRules"
                required
              ></v-text-field>
              <v-select
                solo
                class="mb-1"
                v-model="user.visitorType"
                label="Visitor Type"
                v-if="JSON.stringify(lookupValues !== '[]')"
                :items="visitorTypes"
                hide-details="auto"
                :rules="[(v) => !!v || 'Required']"
                required
              ></v-select>
               <v-select
                solo
                class="mb-1"
                v-model="user.accessZone"
                label="Tier"
                v-if="JSON.stringify(lookupValues !== '[]')"
                :items="tiers"
                hide-details="auto"
                :rules="[(v) => !!v || 'Required']"
                required
              ></v-select>
               <v-select
                solo
                class="mb-1"
                v-model="user.team"
                label="Team"
                :items="teams"
                hide-details="auto"
                :rules="[(v) => !!v || 'Required']"
                required
              ></v-select>
            </v-form>
            <!--NEXT-BUTTON-->
            <v-row class="px-3 mt-4 mb-4">
              <v-btn @click="step--" large class="">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="next" large :loading="loading" :disabled="loading" class="buttonmin">Next</v-btn>
            </v-row>
          </v-window-item>
          <!--GENERAL-DETAILS-WINDOW-3 -->
          <v-window-item :value="3">
            <v-form ref="registerUser3" v-model="valid1" lazy-validation>
              <v-text-field
                solo
                class="mb-1"
                v-model="user.contactNumber"
                label="Contact Number"
                hide-details="auto"
                :rules="telephoneNumberRules"
                required
              ></v-text-field>
                <v-text-field
                solo
                class="mb-1"
                v-model="user.address"
                label="Address"
                hide-details="auto"
                :rules="addressRules"
                required
              ></v-text-field>
                <v-text-field
                solo
                class="mb-1"
                v-model="user.postcode"
                label="Postcode"
                hide-details="auto"
                :rules="postcodeRules"
                required
              ></v-text-field>
            </v-form>
            <!--NEXT-BUTTON-->
            <v-row class="px-3 mt-4 mb-4">
                <v-btn @click="step--" large class="buttonmin">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="next" large :loading="loading" :disabled="loading" class="buttonmin">Next</v-btn>
            </v-row>
          </v-window-item>
          <!--GENERAL-DETAILS-WINDOW-4 -->
          <v-window-item :value="4">
            <v-form ref="registerUser4" v-model="valid1" lazy-validation>
               <v-select
                solo
                class="mb-1"
                v-model="user.sex"
                label="Gender"
                hide-details="auto"
                v-if="JSON.stringify(lookupValues !== '[]')"
                :items="genderTypes"
              ></v-select>
               <!-- <v-text-field
                solo
                class="mb-1"
                v-model="user.NHSnumber"
                label="NHS number"
                hide-details="auto"
              ></v-text-field>
               <v-select
                solo
                class="mb-1"
                v-model="user.ethnicity"
                label="Ethnicity"
                v-if="JSON.stringify(lookupValues !== '[]')"
                :items="ethnicityTypes"
                hide-details="auto"
                :rules="ethnicityRules"
                required
              ></v-select> -->
              <template>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Date of Birth"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      solo
                      class="mb-1"
                      hide-details="auto"
                      :rules="DOBRules"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="date"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1950-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </template>
              <div class="d-flex align-start mt-5" >
                <div style="width: 100px !important;" class="">
                  <v-checkbox
                    color="white"
                    v-model="user.privacyPolicyConsent"
                    hide-details
                    :rules="[(v) => !!v]"
                    required
                    dark
                    class="d-flex align-start"
                    style="position: relative !important; bottom:20 !important;">
                  </v-checkbox>
                </div>
                <div class="text-left">
                    I have completed this form as accurately as possible and accept the <span style="cursor:pointer !important;" v-on:click="privacyDialog = true" class="font-weight-bold secondary--text">Terms and Conditions</span>
                </div> 
                </div>
            </v-form>
            <!--NEXT-BUTTON-->
            <v-row class="px-3 mt-4 mb-4">
              <v-btn @click="step--" large class="buttonmin">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="next" large :loading="loading" :disabled="loading" class="buttonmin">Next</v-btn>
            </v-row>
          </v-window-item>

          <!--PASSWORD-WINDOW-->
          <v-window-item :value="5">
            <v-form ref="userPassword" v-model="valid1" lazy-validation>
              <v-text-field
                v-model.trim="password"
                solo
                class="mb-1"
                hide-details="auto"
                label="Password"
                :append-icon="show ? 'icons8-eye' : 'icons8-invisible'"
                :type="show ? 'text' : 'password'"
                @click:append="show = !show"
                :rules="passwordRules"
                required
              ></v-text-field>
              <v-text-field
                v-model.trim="confirmPassword"
                solo
                class="mb-2"
                hide-details="auto"
                label="Confirm Password"
                :append-icon="show1 ? 'icons8-eye' : 'icons8-invisible'"
                :type="show1 ? 'text' : 'password'"
                @click:append="show1 = !show1"
                :rules="[confirmRules, passwordConfirmationRule]"
                required
              ></v-text-field>
            </v-form>
            <v-row class="px-3 mt-4 mb-4">
              <v-btn @click="step--" large class="buttonmin">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="register" large :loading="loading" :disabled="loading" class="buttonmin">Register</v-btn>
            </v-row>
          </v-window-item>
          <!-- Error message -->
          <v-window-item :value="6">
            <div class="pa-4 text-center" v-if="accountCreated === true">
                  <v-progress-linear indeterminate color="primary" ></v-progress-linear>
              <h3 class="title font-weight-light mb-2">
                Welcome to the admin dashboard of International Ice Hockey Federation Events
              </h3>
              <span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
            </div>
            <div class="pa-4 text-center" v-else>
              <h3 class="title font-weight-light mb-2">Something went wrong</h3>
              <h4 v-if="registerErrorMessage != null">{{registerErrorMessage}}</h4>
              <br/><br/>
              <span class="caption grey--text">Try again!</span>
            </div>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
            <v-row class="px-5 justify-center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="text-center"> 
        <appfooter />
      </v-col>
    </v-row>
    <!-- end of USER-INPUT-->
     <!--notification-component-->
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
    </transition>
      <v-dialog style="z-index: 3000 !important;"
      v-model="privacyDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="background">
        <v-toolbar dark color="secondary heading5 black--text">
          Privacy and Terms & Conditions
          <v-spacer></v-spacer>

          <v-icon class="black--text" @click="privacyDialog = false">mdi-close</v-icon>
        </v-toolbar>

        <PrivacyTermsConditions />
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import PrivacyTermsConditions from "../../components/termsAndConditions.vue";
import axios from "axios";

class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}
export default {
  name: "RegisterAdmin",
    components: {
    PrivacyTermsConditions,
  },
  data() {
    return {
      snackbarKey: 0, // notifications
      snackbar: {}, //notifications
      step: 1, //window step
      menu:false,
      date: null,
      valid: true, // validation for general information form
      valid1: true, // validation for password form
      valid2:true,
      valid3:true,
      show: false, // show/hide password
      show1: false, // show/hide confirm password
      lookupValues:[],
      tiers:["Tier 1","Tier 2", "Tier 3"],
      user: {
        accessZone:"black",
        status: "PENDING",
        userID: "",
        userLevel: 100,
        userType: "admin",
        firstName: "",
        lastName:"",
        // NHSnumber:"",
        sex:"",
        dob:"",
        contactNumber: "",
        // ethnicity:"",
        address:"",
        postcode:"",
        email: "",
        company: "",
        jobTitle: "",
        privacyPolicyConsent: false,
        accountCreatedAt: "",
        team: '',
        visitorType: "IIHF Staff",
      },
      teams: [ 'Coventry', 'Manchester', 'Nottingham', 'Sheffield', 'IIHF' ],
      ethnicityTypes: [
        {text: "WHITE", value:"WHITE"},
        {text:"WHITE AND ASIAN", value:"WHITE AND ASIAN"},
        {text:"WHITE AND BLACK AFRICAN", value:"WHITE AND BLACK AFRICAN"},
        {text:"WHITE AND BLACK CARIBBEAN", value:"WHITE AND BLACK CARIBBEAN"},
        {text:"WHITE BRITISH", value:"WHITE BRITISH"},
        {text:"WHITE IRISH", value:"WHITE IRISH"},
        {text:"WHITE OTHER", value:"WHITE OTHER"},
        {text:"BANGLADESHI", value:"BANGLADESHI"},
        {text:"BLACK - AFRICAN", value:"BLACK - AFRICAN"},
        {text:"BLACK - CARIBBEAN", value:"BLACK - CARIBBEAN"},
        {text:"BLACK - OTHER", value:"BLACK - OTHER"},
        {text:"CHINESE", value:"CHINESE"},
        {text:"INDIAN", value:"INDIAN"},
        {text:"PAKISTANI", value:"PAKISTANI"},
        {text:"ISC - UNSPECIFIED", value:"ISC - UNSPECIFIED"},
        {text:"ANY OTHER ETHNIC CATEGORY", value:"ANY OTHER ETHNIC CATEGORY"},
        {text:"ANY OTHER MIXED GROUP", value:"ANY OTHER MIXED GROUP"},
        {text:"OTHER/MIXED", value:"OTHER/MIXED"},
        {text:"UNKNOWN", value:"UNKNOWN"},
      ],
      genderTypes: [
        {text:"MALE", value:"MALE"},
        {text:"FEMALE", value:"FEMALE"},
        {text:"I PREFER NOT TO SAY", value:"UNKNOWN"},
      ],
    visitorTypes: [
        { text: "Broadcast", value:"Broadcast" },
        { text: "IIHF Staff", value:"IIHF Staff" },
        { text: "IHUK Staff", value:"IHUK Staff" },
        { text: "Medical", value:"Medical" },
        { text: "Officials", value: "Officials"},
        { text: "Off-Ice Officials", value:"Off-Ice Officials"},
        { text: "Photographers", value:"Photographers" },
        { text: "Player", value: "Player"},
        { text: "Safety Steward", value:"Safety Steward" },      
        { text: "Team Observers", value: "Team Observers"},                                   
        { text: "Team Support", value:"Team Support" },
        { text: "Technical", value:"Technical" },
        { text: "Written Media", value:"Written Media" },
        { text: "Other", value:"Other" },                                      
      ],   
      accountCreated: true, // show thank you/ try gain message at the end
      loader: null,
      loading1:null,
      loading: false,
      password: "", // password input
      confirmPassword: "", // check password
      privacyDialog: false,
      // * ACCESS CODE VARIABLES
      // TODO - change access password
      accessPassword: "VINDICO2021SWAPP!!", // ? ACCESS PASSwORD
      accessCode: '', // TODO remove before go live // user input code
      dialogLogin: true, // login dialog
      authSuccess: false, // TODO change back to false before go live // * when true - closes the overlay
      authMessage: null, // message displayed to user
      authCode: null, // * 1 for correct password, 0 for incorrect

      // * VALIDATIONS RULES
      nameRules: [(v) => !!v || "Full name is required"],
      confirmRules: [(v) => !!v || "Confirm password required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      telephoneNumberRules: [(v) => !!v || "Telephone Number is required"],
      registerErrorMessage: null,
      integration: [],
      DOBRules: [(v) => !!v || "Date of Birth is required"],
      // NHSnumberRules:  [(v) => !!v || "NHS number is required"],
      // ethnicityRules: [(v) => !!v || "Ethnicity is required"],
      confirmRulesCompany: [(v) => !!v || "Company/Organisation is required"],
      jobRules: [(v) => !!v || "Job Title is required"],
      addressRules:  [(v) => !!v || "Address is required"],
      postcodeRules: [(v) => !!v || "Postcode is required"],
    };
  },
   watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    loader1() {
      const l = this.loader1;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader1 = null;
    },
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },
  computed: {
    // * Checks if the password matches the confirm password
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || "Password must match";
    },
  },
  methods: {
      tiersFilter(){
           var tier1 = "Officials, IIHF Staff,IHUK Staff, Player";
           var tier2 = "Off-Ice Officials,Medical,Safety Steward, Team Observers, Team Support, Technical"
            if (tier1.includes(this.userForm.visitorType)){
                this.userForm.accessZone = this.tiers[0];
            }else if(tier2.includes(this.userForm.visitorType)){
                this.userForm.accessZone = this.tiers[1];
            }else{
                this.userForm.accessZone= this.tiers[2];
            }
      },
    save (date) {
        this.$refs.menu.save(date);
        this.user.dob = date;
    },
    enterAccessCode() {
      // * Access code validation
      var v = this;
      if (this.accessCode === this.accessPassword) {
        v.authSuccess = true; // * close the overlay
        v.authCode = 1; // * success code
        v.authMessage = "The access code was correct";
        v.accessCode = null;
      } else {
        v.authSuccess = false; // ! keep the overlay open
        v.authCode = 0; // ! error code
        v.authMessage = "The access code you have entered is incorrect";
      }
    },
    next() {
      if (this.step ===1){
         if (this.$refs.registerUser.validate()) {
          this.step++; // * move to the next step 
        } else {
          // // console.log("not valid");
          // ! did not pass validation
          this.snackbar = new SNACKBAR(true,'errorSnackbar',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
          this.snackbarKey++;
        }
      }else if (this.step === 2) {
         if (this.$refs.registerUser2.validate()) {
          this.step++; // * move to the next step 
        } else {
          // // console.log("not valid");
          // ! did not pass validation
          this.snackbar = new SNACKBAR(true,'errorSnackbar',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
          this.snackbarKey++;
        }
      }else if (this.step === 3) {
         if (this.$refs.registerUser3.validate()) {
          this.step++; // * move to the next step 
        } else {
          // // console.log("not valid");
          // ! did not pass validation
          this.snackbar = new SNACKBAR(true,'errorSnackbar',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
          this.snackbarKey++;
        }
      }else if (this.step === 4) {
         if (this.$refs.registerUser4.validate()) {
          this.step++; // * move to the next step 
        } else {
          // // console.log("not valid");
          // ! did not pass validation
          this.snackbar = new SNACKBAR(true,'errorSnackbar',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
          this.snackbarKey++;
        }
      }
    },
    register() {
      var t = this;
      if (this.$refs.userPassword.validate()) {
        this.step++; // * move to the next step 
        // * create user
        t.user.email = t.user.email.toLowerCase();
        t.$firebase.auth.createUserWithEmailAndPassword(t.user.email, t.password).then((user) => {
            // //console.log(JSON.stringify(user, null, 2));
            t.user.userID = user.user.uid; // * assign firebase auth user id to firebase user in collection
            t.user.accountCreatedAt = moment().toISOString();
            ////console.log("uid: " + user.uid + "\nsecond type: " + user.user.uid);
            /////console.log("User: " + JSON.stringify(t.user, null, 2));
            // * add to the users collection
            t.$firebase.db
              .collection("users")
              .doc(user.user.uid)
              .set(t.user)
              .then((Result) => {
                console.log("result: " + Result);
              })
              .catch((err) => {
                console.log(err);
              });
            t.accountCreated = true;
             // * assign this user to the current logged in user
            //t.$store.commit("setUser", user);
            t.snackbar = new SNACKBAR(true,'success',"Your account was created.",500); // update snack bar with success
            t.snackbarKey++;
            

            // * send verification email to user
            var currUser = t.$firebase.auth.currentUser;
            currUser.sendEmailVerification().then(function() {
                //t.snackbar = new SNACKBAR(true,'yellow',"An account verification email has been sent to you.",500); // update snack bar with error
                //t.snackbarKey++;
              })
              .catch(function(error) {
                // ! verification email error
                console.log("send verification email error: " + error);
                //t.snackbar = new SNACKBAR(true,'errorSnackbar',error,500); // update snack bar with error
                //t.snackbarKey++;
              });

             // ? send custom email
              if(t.integration != undefined && t.integration[0].enabled === true){
                axios.get(t.integration[0].functionURL +"?firstName=" + t.user.firstName + "&lastName=" + t.user.lastName + "&email=" + t.user.email).then(response => {

                  if (response.data.result === true) {
                      t.snackbar = new SNACKBAR(true, 'success', 'An email has been sent out to your account',2000); 
                      t.snackbarKey++;
                  } else {
                    t.snackbar = new SNACKBAR(true, 'errorSnackbar', 'Error sending the email',2000); 
                      t.snackbarKey++;
                  }
                });
              }

            // * redirect user to homepage
            setTimeout(function() {
              t.$router.push("/").catch(error => {
                console.log(error.message)
              });
            }, 1500); // ? added time out to allow the current user to be set
          })
          .catch((err) => {
            // ! firebase registering error
            t.registerErrorMessage = err;
            t.accountCreated = false;
            console.log("firebase registering error: " + err);
            t.snackbar = new SNACKBAR(true,'errorSnackbar',err,500); // update snack bar with error
            t.snackbarKey++;
          });
      } else {
        // ! user did not pass input validation
        t.accountCreated = false;
        t.snackbar = new SNACKBAR(true,'errorSnackbar',"Fill out all the fields correctly in order to proceed.",500); // update snack bar with error
        t.snackbarKey++;
      }
    },
  
   async readIntegration() {
      var t = this;
      await this.$firebase.db
        .collection("integrations")
        .where("name", "==", 'customEmail')
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            //docID = doc.id;
           // console.log("integration:  " + JSON.stringify(doc.data(), null,2));
            t.integration.push(doc.data());
          });
        });
    }
  },
  mounted() {
    this.readIntegration();
  },
  created() {
      this.readDocuments('lookupValues', 'lookupValues'); // get list of questions from database
         //var email = window.localStorage.getItem("emailForSignIn");
         //console.log("email: " +email)
  },
};
</script>
