<template>
    <v-row :class="{ 'justify-left ml-8': $vuetify.breakpoint.smAndUp, 'justify-center ml-0': $vuetify.breakpoint.xsOnly }" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="10" xl="10" class="text-left pa-2">
            <v-row class="justify-left" no-gutters>
                <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" class="align-center text-left pa-4">
                    <!-- // * EVENTS / ADD EVENT -->
                    <div class="d-flex align-center ">
                        <div class="pb-1 heading4 secondary--text">Events</div>
                        <v-spacer />
                        <div><v-btn class="secondary" @click="formAction('Add', '')">Add Event</v-btn></div>
                    </div>
                    <!-- // * EVENT SEARCH / FILTERES -->
                    <v-row no-gutters class="pa-0 mb-4 mt-2">
                        <v-col :class="{ 'pr-1': $vuetify.breakpoint.smAndUp, 'pr-0': $vuetify.breakpoint.xsOnly }" cols="12" xs="" sm="" md="" lg="6" xl="6"><v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dark filled background="white"  ></v-text-field></v-col>
                        <v-col :class="{ 'pl-1': $vuetify.breakpoint.smAndUp, 'pl-0 mt-2': $vuetify.breakpoint.xsOnly }" cols="12" xs="" sm="" md="" lg="6" xl="6">
                            <v-select clearable class="" hide-details label="Filter by Venue"  v-model="venueFilter"   :items="venues"  item-value="id"  dark filled background="white"  >
                                <template slot="selection" slot-scope="data">
                                {{ data.item.venueName }}
                                </template>
                                <template slot="item" slot-scope="data">
                                {{ data.item.venueName }}
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <!-- // * DATA TABLE -->
                    <v-card class="mt-2">
                        <v-data-table
                        :headers="computedHeaders"
                        :items="eventsTableData"
                        :items-per-page="itemsPerPage"
                        class="elevation-1"
                        :search="search"
                        :mobile-breakpoint="0"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="d-flex justify-end">
                                    <div style="width: 50px !important;" ><v-icon small @click="formAction('Edit', item)" class="icons8-edit mr-2"></v-icon>
                                        <v-icon small @click="deleteEvent(item.id, item.eventName)" class="icons8-trash"></v-icon>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                        <!-- {{ picker }}<br />
                        <pre class="body2" align="left">{{ eventForm }}</pre> -->
                    </v-card>
                </v-col>
                <!-- // * EVENT FORM -->
                <transition
                    name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                    mode="out-in"
                >
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="text-left" v-if="showForm">
                        <div class="darkgrey card  pa-4">
                            <v-row align="center">
                                <div class="text-left heading6 ml-4">{{ formType }} Event</div>
                                <v-col cols="12" class="text-left">
                                    <v-form ref="events" v-model="valid" lazy-validation>                    
                                        <v-text-field class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Add a name for the event.']" required label="Name" v-model="eventForm.eventName" dark filled background="white" ></v-text-field>
                                        <v-select class="mb-3" hide-details="auto" :rules="[(v) => !!v || 'Select a venue.']" required label="Venue" v-model="eventForm.venueId" dark filled background="white" :items="venues" item-text="venueName" item-value="id" />                      
                                        <v-date-picker width="100%" color="background" :rules="[(v) => !!v || 'Select a date.']" required v-model="picker"></v-date-picker>                      
                                    </v-form>  
                                </v-col>
                                <v-col cols="12" class="d-flex align-center justify-end">
                                    <div class="mr-2">
                                        <v-btn depressed @click="showForm = false" class="grey">Close</v-btn>
                                    </div>
                                    <v-spacer />
                                    <div class="mr-2">
                                        <v-btn depressed v-if="formType === 'Edit'"  @click="deleteEvent(eventForm.id, eventForm.eventName)" color="white"><span class="black--text">Delete Event</span></v-btn>
                                    </div>
                                    <div>
                                        <v-btn depressed v-if="formType === 'Add'" @click="createEvent()" color="secondary">Save Event</v-btn>
                                        <v-btn depressed v-if="formType === 'Edit'" @click="updateEvent()" color="secondary">Update Event</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </transition>
            </v-row>
            <!-- // * SNACK BAR -->
            <transition
                name="custom-classes-transition"
                enter-active-class="animate__animated animate__fadeIn"
                leave-active-class="animate__animated animate__fadeOut"
                mode="out-in"
            >
                <appsnackbar :status="snackbar.status" :color="snackbar.color" :timeout="snackbar.timeout" :message="snackbar.message" :key="snackbarKey" />
            </transition>
            <!-- // * DELETE DIALOG -->
            <v-dialog
               v-model="deleteDialog"
               persistent
               max-width="300"
               style="z-index: 3000 !important;"
            >
                <v-card>
                    <v-card-title  class="background white--text">
                    Delete Event
                    </v-card-title>
                    <v-card-text class="mt-6"> <p class="subtitle1">Are you sure you want to delete the event <strong>{{deleteName}}</strong>?</p></v-card-text>
                    <v-card-actions class="">
                    <v-spacer></v-spacer>
                    <v-btn class="lightgrey" @click="noAction()"> No</v-btn>
                    <v-btn class="secondary" @click="yesAction()">Yes</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog style="z-index: 3000 !important;"
              v-model="deleteDialog"
              persistent
              max-width="300">
              <v-card>
                <v-card-title  class="background white--text">
                 Delete Event
                </v-card-title>
                <v-card-text class="mt-6"> <p class="subtitle1">Are you sure you want to delete the event <strong>{{deleteName}}</strong>?</p></v-card-text>
                <v-card-actions class="">
                  <v-spacer></v-spacer>
                  <v-btn class="lightgrey" @click="noAction()"> No</v-btn>
                  <v-btn class="secondary" @click="yesAction()">Yes</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<script>

// Clases
class SNACKBAR {
    constructor(status, color, message, timeout) {
        this.status = status;
        this.color = color;
        this.message = message;
        this.timeout = timeout;
    }
}

class EVENTFORM {
    constructor(id, eventName, eventDate, venueId, createdUserId, createdDatetime, updatedUserId, updatedDatetime) {
        this.id = id;
        this.eventName = eventName;
        this.eventDate = eventDate;
        this.venueId = venueId;
        this.createdUserId = createdUserId;
        this.createdDatetime = createdDatetime;
        this.updatedUserId = updatedUserId;
        this.updatedDatetime = updatedDatetime;
    }
}

export default {
    data(){
        return {
            deleteDialog: false,
            deleteName: '',
            deleteId: '',
            search: '',
            itemsPerPage: 1,
            snackbarKey: 0,
            snackbar: {},
            showForm: false,
            formType: '', 
            eventForm: {},
            headers: [
                { text: 'ID', value: 'id', align: 'start', sortable: true, hidden: true, hide: true },
                { text: 'Name', value: 'eventName', align: 'start', sortable: true, hidden: false },
                { text: 'Venue', value: 'venueName', align: 'start', sortable: true, hidden: false },
                { text: 'Date', value: 'eventDateHuman', align: 'start', sortable: false, hidden: false },
                { text: 'Created By', value: 'createdUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Created Date', value: 'createdDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Updated By', value: 'updatedUserId', align: 'start', sortable: true, hidden: true },
                { text: 'Updated Date', value: 'updatedDatetime', align: 'start', sortable: true, hidden: true },
                { text: 'Action', value: 'actions', align: 'end', hidden: false, hide: true },
            ],
            events: [],
            venues: [],
            users: [],
            picker: new Date().toISOString().substr(0, 10),
            valid: true,
            venueFilter: '',
        }
    },
     computed: {
        eventsTableData() {
            var events = this.filteredEventsAdmin;
            if ((this.venueFilter !== '') && (this.venueFilter !== undefined)) {
                events = events.filter(event => event.venueId === this.venueFilter)
            }
            return events.map(e => {
                return {
                    id: e.id,
                    eventName: e.eventName,
                    venueId: e.venueId,
                    venueName: this.lookupVenue(e.venueId, 'venueName'),
                    eventDate: e.eventDate,
                    eventDateHuman: this.$moment(e.eventDate, 'x').format('DD/MM'),
                    createdUserId: this.lookupUser(e.createdUserId, 'fullName'),
                    createdDatetime: this.$moment(e.createdDatetime, 'x').format('DD MMM YY | HH:mm:ss'),
                    updatedUserId: this.lookupUser(e.updatedUserId, 'fullName'),
                    updatedDatetime: this.$moment(e.updatedDatetime, 'x').format('DD MMM YY | HH:mm:ss') 
                  };
            });
        },
    },
    watch: {
        picker: {
            handler() {
                this.eventForm.eventDate = this.$moment(this.picker, 'YYYY-MM-DD').format('x');
            },
            deep: true
        }
    },
    methods: {
        formAction(action, item) {
            this.formType = action;
            if (action === 'Add') {
                this.eventForm = new EVENTFORM(null, '', '', null, null, null, null, null); // clear event form
                this.eventForm.eventDate = this.$moment().format('x');
            } else {
                this.picker = this.$moment(item.eventDate, 'x').format('YYYY-MM-DD');
                this.eventForm = new EVENTFORM(item.id, item.eventName, item.eventDate, item.venueId, item.createdUserId, item.createdDatetime, item.updatedUserId, item.updatedDatetime);
            }
            this.showForm = true; // show form
            
            this.$nextTick(() => {
                if (this.$vuetify.breakpoint.name === 'xs') {
                    this.$vuetify.goTo(700)
                }
            });
        },
        async createEvent() {
            if (this.$refs.events.validate()) {
                delete this.eventForm.id; // remove the id as we are creating a new event
                var result = await this.createDocument('events', this.eventForm) // add menu through mixins
                if (result.code === 1) {
                    this.snackbar = new SNACKBAR(true,'success','Event Added',2000); // update snack bar with success
                    this.snackbarKey++
                    this.eventForm = new EVENTFORM(null, '', '', null, null, null, null, null); // clear event form
                    this.showForm = false;
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Adding Event',3000); // update snack bar with error
                    this.snackbarKey++
                }
          }else{
            this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
            this.snackbarKey++
            }
        },
        async updateEvent() {
            if (this.$refs.events.validate()) {
                var result = await this.updateDocument('events', this.eventForm); // update event through mixins
                if (result.code === 1) { // if update event was successful
                    this.snackbar = new SNACKBAR(true,'success','Event Updated',2000); // update snack bar with success
                    this.snackbarKey++ // update snack bar component key to clear component
                    this.eventForm = new EVENTFORM(null, '', '', null, null, null, null, null); // clear event form
                    this.showForm = false; // hide form
                } else {
                    this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Updating Event',3000); // update snack bar with error
                    this.snackbarKey++ // update snack bar component key to clear component
                }
            }else{
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Fill in every field in order to proceed.',3000); // update snack bar with error
                this.snackbarKey++;
            }
        }, 
               
         deleteEvent(id, name) {
            this.deleteDialog = true;
            this.deleteName = name;
            this.deleteId = id;
        },
        async yesAction(){
            var result = await this.deleteDocument('events', this.deleteId); // delete event through mixins
            if (result.code === 1) {
                this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'success','Event Deleted',1000); // update snack bar with success
                this.snackbarKey++ // update snack bar component key to clear component
            } else {
                this.deleteDialog = false;
                this.showForm = false;
                this.snackbar = new SNACKBAR(true,'errorSnackbar','Error Deleting Event',3000); // update snack bar with error
                this.snackbarKey++ // update snack bar component key to clear component
            }
        },
        noAction(){
            this.deleteDialog = false;
            this.deleteName = '';
            this.deleteId = '';
        }
    },
    created() {
        // on create set default data objects
        this.eventForm = new EVENTFORM(null, '', '', null, null, null, null, null); // clear event form
        this.snackbar = new SNACKBAR(false,'black','',3000); // set default
    },
    async mounted() { 
        this.readDocuments('users', 'users'); // get list of questions from database
        this.readDocuments('venues', 'venues'); // get list of events from database
        this.readDocuments('events', 'events'); // get list of events from database
        var itemsPerPage
        switch (this.$vuetify.breakpoint.name) {
            case 'xs': itemsPerPage = 10; break;
            case 'sm': itemsPerPage = 10; break;
            case 'md': itemsPerPage = 10; break;
            case 'lg': itemsPerPage = 20; break;
            case 'xl': itemsPerPage = 20; break;
            default: itemsPerPage = 1;
        }
        this.itemsPerPage = itemsPerPage
    }
}
</script>